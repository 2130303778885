import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import { connect } from 'kea'
import application from '../kea/application'
import auth from '../kea/auth'
import HTML from '../components/general/util/HTML'
import { ErrorPlaceholder, withErrorBoundary } from '../components/general/util/ErrorBoundaries'
import Image from '../components/general/util/Image'
import WP from '../lib/WP'
import { stripHTML } from '../lib/utils'
import { IMAGE_SIZE } from '../entities/ImageModel'
import iconOverlay from '../assets/ui/tm-video.svg'

import './Videos.scss'
import track from 'react-tracking'
import { Link } from '../components/general/util/Links';

const getPageFromUrl = (link) => {
  const regex = /^(.*\/)([0-9]+)(\/)$/
  return link && parseInt(link.replace(regex, '$2'))
}

@connect({
  actions: [
    application, [
      'setViewData',
      'setRendered'
    ]
  ],
  props: [
    application, [
      'view',
    ],
    auth, [
      'premiumUser',
    ],
  ]
})
class Videos extends Component {
  static propTypes = {
    view: PropTypes.object,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    setRendered: PropTypes.func.isRequired,
    premiumUser: PropTypes.bool,
  }

  state = {
  }

  constructor () {
    super()
    this.loadPage()
  }

  loadScripts () {
    const bbw = document.querySelectorAll('script[src*=".bbvms.com"]')
    bbw.forEach(child => {
      const parent = child.parentElement
      const sibling = child.nextSibling
      const addScript = document.createElement('script')
      addScript.setAttribute('src', child.getAttribute('src'))
      document.body.appendChild(addScript)

      parent.removeChild(child)
      parent.insertBefore(addScript, sibling)
    })
  }

  componentWillUnmount () {
    this.props.setRendered(false)
  }


  async loadPage () {
    const page = await WP.getForURL(WP.url + '/videot/')
    this.actions.setViewData(page.data)
    this.props.setRendered(true)
    setTimeout(() => {
      this.loadScripts()
    }, 1)
  }


  render () {
    const {
      content,
      title,
      excerpt,
      createdDate,
      modifiedDate,
      link,
      featuredMedia,
      headerVideoMp4,
      ingress,
    } = this.props.view

    const fullLink = window.location.origin + link
    const metaTags = {
      'og:locale': 'fi_FI',
      'og:type': 'article',
      'og:title': stripHTML(title),
      'og:description': stripHTML(excerpt),
      'og:url': fullLink,

      // og:type is article, more tags for it:
      'article:published_time': createdDate,
      'article:modified_time': modifiedDate,

      'http:status': 200,
    }

    /* eslint-disable jsx-a11y/no-onchange */
    /* eslint-disable jsx-a11y/label-has-for */
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    /* eslint-disable jsx-a11y/click-events-have-key-events */
    return <Fragment>
      <Helmet>
        <title>
          {'Videot - Tekniikan Maailma'}
        </title>
        <link rel="canonical" href={fullLink}/>
        <meta name="description" content={metaTags['og:description']}/>
        {Object.entries(metaTags).map(([key, value], i) => Array.isArray(value)
          ? (
            value.map(x => (
              <meta name={key} key={`${key}-${i}`} content={x}/>
            ))
          )
          : (
            <meta name={key} key={`${key}-${i}`} content={value}/>
          ))}
      </Helmet>
      <div styleName="main-image with-video">
        {headerVideoMp4
          ? <video styleName="header-video img" muted playsInline autoPlay loop> {/* eslint-disable-line */}
            <source src={headerVideoMp4.url}/>
          </video>
          : (featuredMedia
            ? <Image divStyle={'img'} data={featuredMedia} size={IMAGE_SIZE.LARGE} sizes={'(max-width: 1440px) 100vw, 1440px'}/>
            : null)
        }
        <img styleName="icon-overlay" src={iconOverlay} alt="Tekniikan Maailma"/>
      </div>
      <div styleName='magazine-content on-hero-image'>
        <article styleName="article-container">
          <header>
            <h1><HTML>{title}</HTML></h1>
            {ingress ? <div styleName="ingress"><HTML>{ingress}</HTML></div> : null}
          </header>
          <div styleName='article-body'>
            <HTML>{content}</HTML>
          </div>
        </article>
      </div>
    </Fragment>
  }
}

export default track({ gtmContext: ['Videos'] })(withErrorBoundary(
  Videos,
  ErrorPlaceholder()
))
