import React, { Fragment } from 'react'
import Parser from 'html-react-parser'
import domToReact from 'html-react-parser/lib/dom-to-react'
import get from 'lodash/get'

import ImageModel, { IMAGE_SIZE } from '../entities/ImageModel'
import { Link } from '../components/general/util/Links'
import ProductCard from '../components/general/article/ProductCard'
import ScrollVideo from '../components/widgets/ScrollVideo'
import ImageCollage from '../components/general/article/ImageCollage'
import LibreForm from '../components/libreform/LibreForm'
import Image from '../components/general/util/Image'
import WP from '../lib/WP'
import { stripApiHostname } from './url'
import styled from 'styled-components'
import { AdSlotArticleBody, AdSlotArticleBodyMob1 } from '../components/general/ads/Ads'
import Loadable from 'react-loadable'
import MagazineMenu from '../components/navigation/MagazineMenu'
import RandomArticle from '../components/widgets/RandomArticle'
import ProductMemory from '../components/general/article/ProductMemory'
import Question from '../components/general/article/Question'
import FactCard from '../components/general/article/FactCard'
import History from '../components/widgets/History'
import Newsletter from '../components/general/newsletter/Newsletter'

const DynamicExportableTable = Loadable({
  loader: () => import('@otavamedia/om-component-library/lib/ExportableTable'),
  // eslint-disable-next-line react/display-name
  loading: () => <div></div>,
})

const DynamicWeightTableData = Loadable({
  loader: () => import('@otavamedia/om-component-library/lib/WeightTableData'),
  // eslint-disable-next-line react/display-name
  loading: () => <div></div>,
})

/**
 * File specific helpers. Don't try to make them general.
 */
const isLinkNode = tagName => tagName === 'a'
const isImageNode = tagName => tagName === 'img'
const isImageURL = href => href && (~href.indexOf('.jpg') || ~href.indexOf('.png'))
const isParagraphNode = tagName => tagName === 'p'
const hasChildren = node => Array.isArray(node.children)
const capitalizeFirstLetter = str => str[0].toUpperCase() + str.slice(1)

class HTMLParser {
  gravityToLibreFormsMap = {
    3: 'kayttotestikokemukset',
    7: 'kysy-toimitukselta',
    21: 'samsung-kilpailu',
  }

  toc = {
    links: [],
    nextTitleIndex: 0,
  }

  isFirstAd = true

  cleanHTML = (htmlString = '') => {
    let str = (htmlString === null ? '' : htmlString).slice(0)
    // wrap tables in div
    str = str.replace(/<table/g, '<div class="table-div"><table')
    str = str.replace(/<\/table>/g, '</table></div>')

    // unwrap ads from <p> tags
    str = str.replace(/<br\s*\/>\s(<span data-ad[^>]*><\/span>)\s*<br\s*\/>/g, '$1')
    str = str.replace(/(<p[^>]*>((?!<\/p>)[^])*)(<span data-ad[^>]*><\/span>)([^]*?)<\/p>/gi, '$1</p>$3<p>$4</p>')
    str = str.replace(/<p[^>]*><\/p><span data-ad/gi, '<span data-ad') // don't leave empty paragraphs

    str = str.replace(/<br\s*\/>\s(<span data-mobi-ad[^>]*><\/span>)\s*<br\s*\/>/g, '$1')
    str = str.replace(/(<p[^>]*>((?!<\/p>)[^])*)(<span data-mobi-ad[^>]*><\/span>)([^]*?)<\/p>/gi, '$1</p>$3<p>$4</p>')
    str = str.replace(/<p[^>]*><\/p><span data-mobi-ad/gi, '<span data-mobi-ad') // don't leave empty paragraphs

    return str
  }

  /**
   * Add HTML tags to HTML strings if built-in pagination from WP
   * is used in the content. How it works is a mystery.
   */
  paginateHTML = (htmlString = '') => {
    let str = ((typeof htmlString === 'string') ? htmlString : '').slice(0)
    const firstOccurrence = str.indexOf('<!--nextpage-->')

    if (firstOccurrence > -1) {
      // WP adds <p> tags *everywhere*. Get rid of most.
      str = str.replace('<p><!--nextpage--></p>', '<!--nextpage-->')

      let pageOpen = true
      const arr = str.split('<!--nextpage-->')
      for (let i = 0; i < arr.length; i++) {
        if (pageOpen) {
          // Not all <p> tags were removed
          if (arr[i].slice(-3) === '<p>') {
            arr[i] = arr[i].slice(0, -3)
          }

          arr[i] = `${arr[i]}</section>`
          pageOpen = false
        }

        if (!pageOpen) {
          // And some closing </p> tags still exist
          if (arr[i].slice(0, 5) === ' </p>') {
            arr[i] = arr[i].slice(5)
          }

          arr[i] = `<section class='page page-${i + 1}' data-page='${i + 1}'>${arr[i]}`
          pageOpen = true
        }
      }

      str = `<div class='paginated'>
          ${arr.join('')}

          <span class="pagination-placeholder" maxpages="${arr.length}"></span>
      </div>`
    }

    return str
  }

  /**
   * Recursive magic function for tables. No one knows why.
   * @param {*} elem
   */
  getText = (elem) => {
    if (Array.isArray(elem)) {
      return elem.map(this.getText).join('')
    }

    switch (elem.type) {
    case 'tag':
      return elem.name === 'br' ? '\n' : this.getText(elem.children)
    case 'cdata':
      return this.getText(elem.children)
    case 'text':
      return elem.data
    default:
      return ''
    }
  }

  /**
   * Filters out incorrectly placed whitespace nodes from a table
   * @param elem
   * @return {*}
   */
  filterTable = (elem) => {
    if (['table', 'tbody', 'thead', 'tfoot', 'tr'].indexOf(elem.name) !== -1 && elem.children) {
      elem.children = elem.children.filter((el) => el.type !== 'text').map(this.filterTable)
    }
    return elem
  }

  isWeightTableElement = (elem) => {
    return elem.children && elem.children.some((child) => {
      if (child.data && child.data.match(/painoarvo/i)) {
        return true
      }

      if (child.children) {
        return this.isWeightTableElement(child)
      }
      return false
    })
  }

  /**
   * Check if table contains cells with class .adjustable
   * @param elem
   * @return {*}
   */
  isAdjustableTable = (elem) => {
    if (['td', 'th'].indexOf(elem.name) > -1) {
      if (elem.class && elem.class.indexOf('adjustable') > -1) {
        return true
      }

      return this.isWeightTableElement(elem)
    }

    if ((['table', 'tbody', 'thead', 'tfoot', 'tr'].indexOf(elem.name) > -1) && elem.children) {
      return elem.children.some(this.isAdjustableTable)
    }

    return false
  }

  convertToImage = (node, optionalLink = '') => {
    const { src, srcset, class: className, sizes: oldSizes, ...attributes } = node.attribs
    let caption = null

    // If HTML5 mode is enabled in WP, a figure element is used instead of div
    if (node.parent) {
      const parent = node.parent

      if (parent.name === 'div') {
        // Image with caption, no link
        caption = get(parent, 'children[1].children[0].data', null)
      } else if (parent.parent) {
        const parent2 = parent.parent

        if (parent2.name === 'div') {
          // Image with caption, link
          caption = get(parent2, 'children[1].children[0].data', null)
        }
      }
    }

    const sizes = {}
    if (srcset) {
      // Parse srcset string into object format understood by ImageModel.
      // This is done so that ImageGallery can display largest possible image.
      const keys = ['thumbnail', 'medium', 'medium_large', 'large', 'full']
      const values = srcset.match(/(ht[^ ]+ [0-9]+w)/g).map((x) => x.match(/(ht[^ ]+) ([0-9]+)w/))
        .map((x) => ({ source_url: x[1], width: parseInt(x[2]), height: parseInt(x[2]) }))
        .sort((a, b) => a.width - b.width)
      keys.forEach((k, i) => {
        if (values[i]) {
          sizes[k] = values[i].source_url
          sizes[k + '-width'] = values[i].width
          sizes[k + '-height'] = values[i].height
        }
      })
    }
    const model = new ImageModel({ url: src, caption, ...attributes, sizes })

    const attrs = { ...attributes }
    delete attrs.style

    if (!optionalLink || isImageURL(optionalLink)) {
      return <div>
        <Image addToGallery data={model} size={IMAGE_SIZE.ORIGINAL} srcSet={srcset} className={className} {...attrs} />
      </div>
    } else {
      return <div>
        <Link to={{link: optionalLink}}>
          <Image data={model} size={IMAGE_SIZE.ORIGINAL} srcSet={srcset} className={className} {...attrs} />
        </Link>
      </div>
    }

  }

  /**
   * html-react-parser calls this function on each domNode when parsing html (used by <HTML> element).
   * Here you can change any html element into something else. You can return a JSX element, or return null to
   * keep the original element as it is.
   *
   * options:
   * - filterDivs: filter out any divs, img and video tags, except if they have 'always-show' class. Used
   *               for article content when showing a paywall.
   * - createToc: create a Table of Contents element
   * - linkedProductCards: Product cards to be inserted into the content
   * - linkedProductMemories: Product memories to be inserted into the content
   * - linkedQuestions: Questions to be inserted into the content
   *
   * @param domNode
   * @param options
   * @returns {JSX.Element|*}
   */
  replaceTagNode = (domNode, options = {}) => {
    const { children, attribs = {}, name: tagName } = domNode
    const child = children && children[0]

    /*
     * Images.
     */
    if (isImageNode(tagName)) { // Plain img
      return this.convertToImage(domNode)
    }

    // Image inside a link
    if (isLinkNode(tagName)) {
      if (isImageNode(get(child, 'name'))) {
        const childAttribs = child.attribs
        const { src } = childAttribs

        if (isImageURL(src)) {
          if (options.filterDivs && !childAttribs.class.includes('always-show')) {
            return <i></i>
          }
          return this.convertToImage(child, attribs.href)
        }
      }
    }
    if (tagName === 'html') {
      return <Fragment>{domToReact(domNode.children[0].children, this.parserOptions())}</Fragment>
    }

    if (tagName === 'h2' && options.createToc) {
      this.toc.links.push({
        text: domNode.children[0].data,
        target: `#anchor-${this.toc.nextTitleIndex}`,
      })
      return (
        <Fragment>
          <span id={`anchor-${this.toc.nextTitleIndex++}`} className="anchor"></span>
          <h2>{domNode.children[0].data}</h2>
        </Fragment>
      )
    }

    // p > a > img
    if (isParagraphNode(tagName) && child && isLinkNode(get(child, 'name'))) {
      if (hasChildren(child) && isImageNode(get(child.children[0], 'name'))) {
        const childImg = child.children[0]
        const childAttribs = childImg.attribs
        const { src } = childAttribs

        if (isImageURL(src)) {
          if (options.filterDivs && !childAttribs.class.includes('always-show')) {
            return <div></div>
          }
          return <div>{this.convertToImage(childImg, child.attribs.href)}</div>
        }
      }
    }

    // div > a > img
    if (tagName === 'div' && child && isLinkNode(get(child, 'name'))) {
      if (hasChildren(child) && isImageNode(get(child.children[0], 'name'))) {
        const childImg = child.children[0]
        const childAttribs = childImg.attribs
        const { src } = childAttribs

        if (isImageURL(src)) {
          if (options.filterDivs && !childAttribs.class.includes('always-show')) {
            return <div></div>
          }
          if (child.next && child.next.attribs.class.includes('wp-caption-text')) {
            return <div className={attribs.class}>
              {this.convertToImage(childImg, child.attribs.href)}
              <div className="wp-caption-box">
                <div className="wp-caption-shadow"></div>
                <p className="wp-caption-text">{domToReact(child.next.children, {})}</p>
              </div>
            </div>
          }
          return <div>{this.convertToImage(childImg, child.attribs.href)}</div>
        }
      }
    }

    if (options.filterDivs) {
      if (tagName === 'div') {
        if (!children.some(thisChild => thisChild.attribs && thisChild.attribs.class &&
          thisChild.attribs.class.includes('always-show'))) {
          // if no child element has 'always-show' class, remove this element
          return <div></div>
        }
      }
      if (tagName === 'img' && !attribs.class.includes('always-show')) {
        // If we want to filter out any imgs, return nothing. Used when paywall is shown.
        return <div></div>
      }
    }

    /*
     * Tables
     */
    if (tagName === 'table') {
      domNode = this.filterTable(domNode)
      const filteredChildren = domNode.children
      const containsClass = (attribs.class && attribs.class.indexOf('acf-table-editor') > -1)

      if (containsClass || this.isAdjustableTable(domNode)) {
        const rows = filteredChildren[0].name === 'tr' ? filteredChildren : filteredChildren[0].children
        const productData = []

        rows.forEach((tr) => {
          return tr.children.forEach((td, idx) => {
            productData[idx] = productData[idx] || []
            productData[idx].push(this.getText(td))
          })
        })

        const options = productData.shift()
        const weights = productData.shift()
        const StyledWeightTableData = styled(DynamicWeightTableData)`
  table button {
    height: 100%;
    width: 100%;
    background: transparent;
    border: none;
    color: rgb(0, 0, 255);
    line-height: 14px;
    font-size: 14px;
    padding: 1rem 0.75rem 0.75rem;
  }
  table .with-button {
    padding: 0 !important;
  }
  .button {
    @include button($padding: 0.5rem 0.5rem);
    margin: 0 0.5rem 0.25rem 0;
  }
  .link {
    background: transparent;
    color: rgb(0, 0, 255);
    line-height: 14px;
    font-size: 14px;
    border: none;
  }
`

        return <StyledWeightTableData data={{ products: productData, options, weights }}/>
      } else {
        const tableId = 'table' + Math.floor(Math.random() * 1000000)
        const className = (attribs.class && attribs.class.includes('aligncenter')) ? 'aligncenter' : ''
        domNode.id = tableId
        return <DynamicExportableTable id={tableId} className={className}>
          <table id={tableId}>{domToReact(domNode.children, this.parserOptions())}</table>
        </DynamicExportableTable>
      }
    }

    /*
     * Product cards
     */
    if (attribs['data-productcard']) {
      const { linkedProductCards } = options
      const { 'data-productcard': id } = attribs

      if (!linkedProductCards || !linkedProductCards[parseInt(id, 10) - 1]) {
        console.warn('Linked product cards found in content, but nothing was passed to the parser.')
        return
      }

      linkedProductCards[parseInt(id, 10) - 1].shortCode = true
      return <ProductCard card={linkedProductCards[parseInt(id, 10) - 1]} shortCode/>
    }

    if (attribs['data-historycard']) {
      const { linkedHistoryCards } = options
      const { 'data-historycard': id } = attribs

      if (!linkedHistoryCards || !linkedHistoryCards[parseInt(id, 10) - 1]) {
        console.warn('Linked history cards found in content, but nothing was passed to the parser.')
        return
      }

      linkedHistoryCards[parseInt(id, 10) - 1].shortCode = true
      return <History card={linkedHistoryCards[parseInt(id, 10) - 1]} shortCode/>
    }

    if (attribs['data-factcard']) {
      return <FactCard/>
    }

    if (attribs['scroll-video']) {
      const { scrollVideos } = options
      const { 'scroll-video': id } = attribs

      if (!scrollVideos || !scrollVideos[parseInt(id, 10) - 1]) {
        console.warn('Linked scroll videos found in content, but nothing was passed to the parser.')
        return
      }

      scrollVideos[parseInt(id, 10) - 1].shortCode = true
      return <ScrollVideo video={scrollVideos[parseInt(id, 10) - 1]}/>
    }
    /*
     * Product memories
     */
    if (attribs['data-productmemory']) {
      const { linkedProductMemories } = options
      const { 'data-productmemory': id } = attribs

      if (!linkedProductMemories || !linkedProductMemories[parseInt(id, 10) - 1]) {
        console.warn('Linked product memories found in content, but nothing was passed to the parser.')
        return
      }
      linkedProductMemories[parseInt(id, 10) - 1].shortCode = true
      return <ProductMemory card={linkedProductMemories[parseInt(id, 10) - 1]} shortCode/>
    }
    /*
     * Questions
     */
    if (attribs['data-question']) {
      const { linkedQuestions } = options
      const { 'data-question': id } = attribs

      if (!linkedQuestions || !linkedQuestions[parseInt(id, 10) - 1]) {
        console.warn('Linked questions found in content, but nothing was passed to the parser.')
        return
      }
      linkedQuestions[parseInt(id, 10) - 1].shortCode = true
      return <Question card={linkedQuestions[parseInt(id, 10) - 1]} shortCode/>
    }

    /*
     * Inline ads
     */
    if ('data-ad' in attribs) {
      const firstAd = this.isFirstAd
      this.isFirstAd = false
      return (
        <div className="in-text-ad-slot-wrapper">
          <AdSlotArticleBody firstAd={firstAd}/>
        </div>
      )
    }

    if ('data-mobi-ad' in attribs) {
      return (
        <div className="in-text-ad-slot-wrapper">
          <AdSlotArticleBodyMob1/>
        </div>
      )
    }

    if ('navigation-demo' in attribs) {
      return (
        <div className="navigation-demo">
          <MagazineMenu demo={true} highlight={attribs.highlight}/>
        </div>
      )
    }

    /*
     * Links
     */
    if (attribs.href) {
      const { href } = attribs

      try {
        // If a broken link appears in WP content, it will crash the entire
        // application. This is to avoid that.
        if (new URL(href).origin === WP.url && href.indexOf('/wp-content/') === -1 && href.indexOf('/digilehti/') === -1 && href.indexOf('/nakoislehti/') === -1) {
          return (
            <Link {...attribs} to={{ link: stripApiHostname(href) }} clickLocation="text">
              {domToReact(domNode.children, {})}
            </Link>
          )
        } else {
          return (
            <Link {...attribs} to={{ link: href }} clickLocation="text">
              {domToReact(domNode.children, {})}
            </Link>
          )
        }
      } catch (e) {
        console.log('Broken link found', e)
        console.log(href, domNode)
      }
    }

    /*
     * Random replaces based on className
     */
    if (attribs.class) {
      const className = attribs.class
      const classes = className.split(' ')

      const paginationOpts = {
        page: 1,
        maxPages: parseInt(attribs.maxpages, 10),
        changePage: (page) => console.log('Switch to', page),
        ...options.pagination,
      }

      /*
       * ImageCollage element
       */
      if (className === 'imagecollage') {
        try {
          const { children } = domNode
          const json = JSON.parse(children[0].data)
          const { count, images } = json

          const parsedImages = images.map(i => {
            const model = new ImageModel(i.image)

            return model
          })

          return <ImageCollage count={parseInt(count, 10)} images={parsedImages} id={options.id}/>
        } catch (e) {
          console.log('Error parsing imageCollage', e)
          return <div></div>
        }
      }
      if (className.includes('uutiskirje')) {
        return (
          <div className={className}>
            <Newsletter showAlways={true} wide={true}/>
          </div>
        )
      }
      if (className.includes('random-link')) {
        return (
          <div className={className}>
            <RandomArticle/>
          </div>
        )
      }
      if (className.includes('wp-caption')) {
        if (child && child.attribs && child.attribs.class && child.attribs.class.includes('normal')) {
          return <div className={domNode.attribs.class + ' normal'} id={domNode.attribs.id}>
            {children.map(thisChild => this.replaceTagNode(thisChild, options))}
          </div>
        }
      }
      if (className.includes('wp-caption-text')) {
        if (domNode.prev && domNode.prev.attribs && domNode.prev.attribs.class && domNode.prev.attribs.class.includes('quote')) {
          if (domNode.prev.attribs.class.includes('inverse')) {
            return (
              <div className="wp-caption-box quote inverse">
                <div className="wp-caption-shadow"></div>
                <p className="wp-caption-text">{domToReact(children, {})}</p>
              </div>
            )
          } else {
            return (
              <div className="wp-caption-box quote">
                <div className="wp-caption-shadow"></div>
                <p className="wp-caption-text">{domToReact(children, {})}</p>
              </div>
            )
          }
        } else if (domNode.prev && domNode.prev.attribs && domNode.prev.attribs.class && domNode.prev.attribs.class.includes('standard')) {
          return <p className="wp-caption-text">{domToReact(children, {})}</p>
        }
        return (
          <div className="wp-caption-box">
            <div className="wp-caption-shadow"></div>
            <p className="wp-caption-text">{domToReact(children, {})}</p>
          </div>
        )
      }

      if (className.includes('libre-form') && tagName === 'form') {
        const id = parseInt(attribs['data-form-id'], 10)

        return <LibreForm form={id}/>
      }

      /*
       * Missing gravity forms render ugly things, replace
       */
      if (className === 'gform_not_found') {
        // Even if the form were available, it wouldn't work in the context of this app
        console.warn('Post had a gravity forms shortcode but the form is missing. Remove the form from the post to remove this warning.')
        return <span></span>
      }

      /*
       * Replace gravity forms with libre forms (legacy content)
       */
      if (classes.indexOf('gform_wrapper') > -1) {
        const { id } = attribs
        const gfId = parseInt(id.replace('gform_wrapper_', ''))
        const libreSlug = this.gravityToLibreFormsMap[gfId]

        if (libreSlug) {
          return <LibreForm form={libreSlug}/>
        }

        return <span>[Toimittaja: Käytä GravityForms-lomakkeen sijaan LibreForm-lomaketta!]</span>
      }

      /*
       * Page element created by this.paginateHTML
       */
      if (classes[0] === 'page') {
        const page = parseInt(attribs['data-page'], 10)
        classes.push(page === paginationOpts.page ? 'active' : 'hidden')
        domNode.attribs.class = classes.join(' ')
      }

      /*
       * Pagination element created by this.paginateHTML
       */
      /*
      if (className === 'pagination-placeholder') {
        return <Pagination page={paginationOpts.page} maxPages={paginationOpts.maxPages}
          changePage={paginationOpts.changePage}/>
      }
       */
    }

    return domNode
  }

  /**
   * html-react-parser calls this function for each text node
   */
  replaceTextNode = (domNode, options = {}) => {
    return domNode
  }

  parserOptions = (options) => {
    return ({
      replace: (domNode) => {
        const { type } = domNode
        const replaceMethod = this[`replace${capitalizeFirstLetter(type)}Node`]

        if (replaceMethod) {
          return replaceMethod(domNode, options)
        }

        return domNode
      }
    })
  }

  render = (htmlString = '', options = {}) => {
    this.toc = {
      links: [],
      nextTitleIndex: 0,
    }
    const paginatedHTML = this.paginateHTML(htmlString)
    const parsedComponents = Parser(this.cleanHTML(paginatedHTML), this.parserOptions(options))
    this.isFirstAd = true

    return this.toc.links.length
      ? [
        <div key="article-toc" className="article-toc">
          <ul>
            {this.toc.links.map(link => (
              <li key={link.target}>
                <a href={link.target}>{link.text}</a>
              </li>
            ))}
          </ul>
        </div>,
        parsedComponents
      ]
      : parsedComponents
  }
}

export default new HTMLParser()
